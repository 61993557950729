import { Country } from "../constants/countries";
import dayjs from "dayjs";
import { HttpErrorResponse } from "@angular/common/http";
import { isDevMode } from "@angular/core";
import { CardType } from "../constants/card-types";
import { IPropertyFiltersDateRange } from "../services/property-filters.service";

export interface IIntParam {
    value?: number;
}

export interface IDateParam {
    value?: dayjs.Dayjs;
}

export interface IVhCheckInCheckOutGuestParams {
    checkIn?: dayjs.Dayjs;
    checkOut?: dayjs.Dayjs;
    guests?: number;
}

/** Generates week day labels using DayJs js to account for locale. */
export function weekDaysLabels(): string[] {
    const labels = [];
    const daysInWeek = 7;

    for (let i = 0; i < daysInWeek; i++) {
        labels.push(dayjs().startOf('week').add(i, 'days').format('ddd'));
    }

    return labels;
}

export function filterCountries(allCountries: Country[], filter: string | null): Country[] {
    if (!allCountries?.length) {
        return [];
    }

    if (!filter) {
        return [...allCountries];
    }

    const normalized = filter.toLowerCase();

    const filteredItems = allCountries.filter(item => item.name.toLowerCase().includes(normalized)).sort((a, b) => {
        const aLowercase = a.name.toLowerCase();
        const bLowercase = b.name.toLowerCase();

        if (aLowercase.startsWith(normalized) && !bLowercase.startsWith(normalized)) {
            return -1;
        }
        if (!aLowercase.startsWith(normalized) && bLowercase.startsWith(normalized)) {
            return 1;
        }

        if (aLowercase < bLowercase) {
            return -1;
        }
        if (aLowercase > bLowercase) {
            return 1;
        }

        return 0;
    });

    return filteredItems;
}

/**
 * This JavaScript function is a decorator that assigns an alias to a class property.
 * A decorator is a function that can modify the behavior or appearance of another function or class.
 * The alias function takes a string parameter, _alias, and returns another function that takes two parameters, target and propertyKey.
 * The target is the class that the decorator is applied to, and the propertyKey is the name of the property that the decorator is attached to.
 * The inner function then creates an object called _alias on the target's constructor, if it doesn't exist already, and assigns the _alias
 * parameter as the value of the propertyKey on that object. This way, the class property can be accessed by its original name or by its alias.
 */
export function alias(_alias: string) {
    return function (target: any, propertyKey: string | symbol) {
        target['constructor']['_alias'] = target['constructor']['_alias'] || {};
        target['constructor']['_alias'][propertyKey] = _alias;
    };
}

/**
 * Tests if a string input is null, undefined or contains only white space (TAB, spaces, etc...).
 * @param input The string input to check.
 * @returns True if the input is undefined, null or contains only white spaces, false otherwise.
 */
export function isWhitespace(input?: string | null): boolean {
    return !input?.trim();
}

/**
 * Returns the abbreviation of the specified card type or null if no abbreviation is known for it.
 * @param type The card type
 * @returns The abbreviation of null.
 */
export function getCreditCardAbbreviation(type: CardType): string | null {
    switch (type) {
        case CardType.Visa: return 'VI';
        case CardType.Mastercard: return 'MC';
        case CardType.AmericanExpress: return 'AM';
        case CardType.Discover: return 'DI';

        default: return null;
    }
}

export function extractErrors(err: any): string {
    if (err instanceof HttpErrorResponse) {
        if (err.error?.errors) {
            const parts: string[] = [];

            err.error.errors?.forEach((part: any) => {
                if (part.message) {
                    parts.push(part.message);
                } else {
                    parts.push(isDevMode() ? JSON.stringify(part) : 'unknown error');
                }
            });

            return parts.join(', ');
        }

        return isDevMode() ? JSON.stringify(err) : 'unknown error';
    }

    return err;
}

/**
 * Converts a lead time from string to hours.
 * @param notification The lead time as string.
 * @returns number of hours.
 */
export function extractNotificationHours(notification: string): number {
    if (!notification) {
        return 0;
    }

    let normalized = notification.toUpperCase();
    let result = 0;

    if (normalized.includes('D')) {
        const days = Number.parseInt(normalized.slice(0, normalized.indexOf('D')), 10);
        normalized = normalized.substring(normalized.indexOf('D') + 1);

        result += days * 24;
    }

    if (normalized.includes('H')) {
        const hours = Number.parseInt(normalized.slice(0, normalized.indexOf('H')), 10);
        normalized = normalized.substring(normalized.indexOf('H') + 1);

        result += hours;
    }

    if (normalized) {
        // If we still have data it means no D or H has been found. Treat the number as days.
        result += Number.parseInt(normalized, 10);
    }

    return result;
}

/**
 * Helper method that will format a duration part
 * @param unitValue The value
 * @param unitName The name as singular (week, day, hour)
 * @returns
 */
function formatDurationPart(unitValue: number, unitName: string) {
    const pluralSuffix = unitValue === 1 ? '' : 's';
    return unitValue ? `${unitValue} ${unitName}${pluralSuffix}` : '';
}

/**
 * Converts a notification string to human readable string.
 * @param notificationHours The lead time.
 */
export function convertNotificationTime(notificationHours: number): string {
    const weeks = Math.floor(notificationHours / 168);
    const days = Math.floor((notificationHours % 168) / 24);
    const remainingHours = notificationHours % 24;

    // Array of duration parts
    const durationParts = [
        formatDurationPart(weeks, 'week'),
        formatDurationPart(days, 'day'),
        formatDurationPart(remainingHours, 'hour')
    ].filter(part => part); // Remove empty strings

    if (durationParts.length > 1) {
        const allButLast = durationParts.slice(0, -1).join(', ');
        const last = durationParts[durationParts.length - 1];
        return `${allButLast} and ${last}`;
    }

    return durationParts.length ? durationParts.join(', ') : '0 hours';
}


/**
 * Converts an availability array from the form "1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,9,15,2654,251,0" to an array of numbers.
 * @param availability The availability string in the form mentioned above or without commas (1100209 - but then only up to 9 will be considered number)
 */
export function availabilityStringToNumbers(availability?: string | null): number[] {
    if (!availability || (typeof availability !== 'string')) {
        return [];
    }

    if (availability.indexOf(',') >= 0) {
        return availability.split(',').map(ava => Number(ava));
    } else {
        return availability.split('').map(ava => Number(ava));
    }
}

export interface IChangeOver {
    closedToArrival: boolean;
    closedToDeparture: boolean;
}

export function areRangesSame(range1?: IPropertyFiltersDateRange | null, range2?: IPropertyFiltersDateRange | null): boolean {
    const range1Start = range1?.start ?? null;
    const range1End = range1?.end ?? null;
    const range2Start = range2?.start ?? null;
    const range2End = range2?.end ?? null;

    return (range1Start?.isSame(range2Start, 'day') ?? range1Start === range2Start) &&
        (range1End?.isSame(range2End, 'day') ?? range1End === range2End);
}

export function changeOverFromChar(changeOverChar: string): IChangeOver {
    const result: IChangeOver = {
        closedToArrival: false,
        closedToDeparture: false
    };

    switch (changeOverChar.toUpperCase()) {
        case 'C': {
            result.closedToArrival = false;
            result.closedToDeparture = false;
            break;
        }

        case 'I': {
            result.closedToArrival = false;
            result.closedToDeparture = true;
            break;
        }

        case 'O': {
            result.closedToArrival = true;
            result.closedToDeparture = false;
            break;
        }

        case 'X': {
            result.closedToArrival = true;
            result.closedToDeparture = true;
            break;
        }
    }

    return result;
}