import { Pipe, PipeTransform } from '@angular/core';
import { CountryCode, getExampleNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';

@Pipe({
  name: 'vhPhoneHint',
  standalone: true
})
export class PhoneHintPipe implements PipeTransform {

  public transform(regionCode: string): string {
    if (!regionCode) {
      regionCode = 'US';
    }

    const example = getExampleNumber(regionCode as CountryCode, examples);

    return example?.formatInternational() ?? '';
  }

}
