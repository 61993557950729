export const environment = {
    appTitle: 'VacayHome QA',
    updatesEnabled: true,
    baseEndpoint: 'https://qa-',
    url: 'https://qa.vacayhome.com',
    partner: 'vacayhome',
    supportedCardTypes: ['VI', 'MC', 'AM', 'DI'],
    googleMaps: {
        mapCenterPos: {
            lat: 21.7978,
            lon: -72.165
        }
    },
    gaTrackingId: 'G-15V68D2TR0',
    chatUrl: 'https://home-c37.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=3b80a7de-1bbf-4f8c-89c2-6c5764530213&bu=4600388',
    chatOptions: 'height=600, width=475,toolbar=no,location=no,menubar=no'
};