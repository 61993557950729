import { Pipe, PipeTransform } from '@angular/core';
import { Country } from '../constants/countries';

@Pipe({
  name: 'vhFlagLocation',
  standalone: true
})
export class FlagLocationPipe implements PipeTransform {

  public transform(value: Country): string {
    if (!value) {
      return '';
    }

    return `https://flagcdn.com/${value.code.toLowerCase()}.svg`;
  }

}
