import { AbstractControl, Validators } from '@angular/forms';
import { getCreditCardAbbreviation, isWhitespace } from './utils';
import { CountryCode, parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';
import { IPhoneNumber } from '../components/form-helpers/controls/phone-input/phone-input.component';
import { CardType } from '../constants/card-types';
import { environment } from '../../../environments/environment';


export class CustomValidators extends Validators {
    /**
     * Checks if a control has a valid URL in it.
     * @param control The control to validate.
     */
    public static whitespaceGuard(control: AbstractControl) {
        if (control?.value) {
            if (isWhitespace(control.value)) {
                return { whitespace: true };
            }
        }

        return null;
    }

    /**
     * Validates a phone number control that has a value of type IPhoneNumber.
     */
    public static phone(control: AbstractControl) {
        if (!control?.value?.number) {
            return null; // Consider valid.
        }

        const value = control.value as IPhoneNumber;

        let pn: PhoneNumber | null = null;

        try {
            pn = parsePhoneNumber(value.number ?? '', (value.regionCode || undefined) as CountryCode);
        } catch {
            // Intentionally left blank
        }

        if (pn?.isValid() && value.regionCode === pn.country) {
            return null;
        } else {
            return {
                phone: true
            };
        }
    }

    /**
     * Validates a phone number control that has a value of type IPhoneNumber.
     */
    public static phoneRequired(control: AbstractControl) {
        if (!control) {
            return null;
        }

        if (!control?.value?.number) {
            return {
                required: true
            };
        }

        return null;
    }

    /**
     * Validates a phone number control that has a value of type IPhoneNumber.
     */
    public static supportedCardType(control: AbstractControl) {
        if (!control?.value) {
            return null;
        }

        const cardType = control.value as CardType;
        const abbreviation = getCreditCardAbbreviation(cardType);

        if (!abbreviation || !environment.supportedCardTypes.includes(abbreviation)) {
            return {
                supportedCardType: true
            };
        }

        return null;
    }
}
