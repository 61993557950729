export enum VhPage {
    Home = 'home',
    Book = 'listing',
    Faq = 'faq',
    Destinations = 'destinations',
    Property = 'property',
    SupplierDetails = 'supplierDetails'
}

/**
 * Returns the url of the provided page using data provided in the urlData object.
 * SEE README.MD FOR WHAT TO DO WHEN ADDING A NEW PAGE OR EDITING THE ROUTE FOR AN EXISTING ONE.
 * @param page The page to get the url for.
 * @param urlData Tha data specific to that page.
 * @returns the url to the page.
 */
export function getVhUrl(page: VhPage, urlData?: { [key: string]: number | string }): string {
    switch (page) {
        case VhPage.Home:
            return '/home';
        case VhPage.Faq:
            return '/faq';
        case VhPage.Destinations:
            return '/destinations';
        case VhPage.Property:
            return `/property/${urlData?.['id']}`;
        case VhPage.SupplierDetails:
            return `/supplier/${urlData?.['id']}`;
        case VhPage.Book:
            return `/listing/${urlData?.['id']}`;
    }
}