<h1 mat-dialog-title class="!tracking-normal">About Us</h1>

<mat-dialog-content>
    <p class="pt-4 text-vhGray-100">
        VacayHome Destinations is operated by <a target="_blank" class="!text-vhPrimary-60 font-medium"
            href="{{vhcLink}}" aria-label="VHC home">VacayHome Connect</a>.
    </p>

    <p>
        <a class="!text-vhPrimary-60 font-medium" target="_blank" href="{{vhcLink}}" aria-label="VHC home">VacayHome
            Connect</a>
        is the leading connectivity
        and
        distribution partner for the vacation rental industry, partnering with property managers to enhance their
        visibility and capabilities to rent their properties. We connect billions of dollars of instantly bookable
        inventory to millions of leisure travelers around the world.
    </p>

    <p class="pt-4 text-vhGray-100">
        We work closely with professional property managers from start to finish, to ensure you have a seamless
        experience from the moment you select your ideal vacation accommodation.
    </p>

    <p class="pt-4 text-vhGray-100">
        Our vacation specialists are available to answer your questions and to assist with your travel needs via email
        at <a class="!text-vhPrimary-60 font-medium" href="mailto:{{supportEmail}}"
            aria-label="Support mail">{{supportEmail}}</a>
        or by calling <a class="!text-vhPrimary-60 font-medium" href="tel:{{supportPhone}}"
            aria-label="Support phone">{{supportPhone}}</a>.
    </p>
</mat-dialog-content>

<div mat-dialog-actions class="flex !justify-end !items-center !space-x-3 !px-6 !pb-6 !pt-0">
    <button cdkFocusInitial mat-flat-button mat-dialog-close type="button" color="primary" aria-label="OK button"
        class="font-semibold tracking-normal">
        <span class="font-semibold tracking-normal">OK</span>
    </button>
</div>