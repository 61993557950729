<ng-container [formGroup]="form">
    <mat-select formControlName="country" [placeholder]="placeholder" [ngStyle]="{opacity: disabled ? 0.5 : 1}"
        class="flex-1 h-5">
        <mat-select-trigger>
            <div class="flex items-center mr-2" *ngIf="form?.get('country')?.value">
                <img [src]="form.get('country')?.value | vhFlagLocation" alt="Country flag" loading="lazy"
                    class="w-8 h-5 border border-vhGray-300">
            </div>
        </mat-select-trigger>

        <mat-option>
            <ngx-mat-select-search formControlName="countryFilter" placeholderLabel="Search"
                noEntriesFoundLabel="No countries found.">
                <mat-icon ngxMatSelectSearchClear svgIcon="vhc-close"></mat-icon>
            </ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
            <div class="flex items-center">
                <div class="mr-2 flex items-center">
                    <img [src]="country | vhFlagLocation" alt="Country flag" loading="lazy"
                        class="w-8 border border-vhGray-300">
                </div>

                <span>{{country.name}}</span>
            </div>
        </mat-option>
    </mat-select>

    <input type="tel" formControlName="phone" matInput autocomplete="off" class="ml-4 grow" #focusInput
        [ngStyle]="{opacity: disabled ? 0.5 : 1}" (copy)="onCopy($event)" (paste)="onPaste($event)">

</ng-container>