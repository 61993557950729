<h1 mat-dialog-title class="!tracking-normal">Contact Us</h1>

<mat-dialog-content>
    <form id="contactUsForm" [formGroup]="formGroup!" (ngSubmit)="submit()">
        <div class="flex flex-col md:flex-row gap-3 md:gap-4 pt-4">
            <mat-form-field appearance="outline" class="grow" hideRequiredMarker>
                <mat-label>First Name</mat-label>
                <input type="text" matInput formControlName="firstName">
                <mat-error *ngIf="formGroup?.get('firstName')?.invalid">
                    <vh-control-error label="First name" controlName="firstName" [formGroup]="formGroup!">
                    </vh-control-error>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="grow" hideRequiredMarker>
                <mat-label>Last Name</mat-label>
                <input type="text" matInput formControlName="lastName">
                <mat-error *ngIf="formGroup?.get('lastName')?.invalid">
                    <vh-control-error label="Last name" controlName="lastName" [formGroup]="formGroup!">
                    </vh-control-error>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="flex flex-col md:flex-row gap-3 md:gap-4 pt-3">
            <mat-form-field appearance="outline" class="grow" hideRequiredMarker>
                <mat-label>Phone</mat-label>
                <vh-phone-input formControlName="phone"></vh-phone-input>
                <mat-error *ngIf="formGroup?.get('phone')?.invalid">
                    <vh-control-error label="Phone" controlName="phone" [formGroup]="formGroup!">
                    </vh-control-error>
                </mat-error>
                <mat-hint align="end">{{formGroup?.get('phone')?.value?.regionCode | vhPhoneHint}}</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" class="grow" hideRequiredMarker>
                <mat-label>Email</mat-label>
                <input type="email" matInput formControlName="email">
                <mat-error *ngIf="formGroup?.get('email')?.invalid">
                    <vh-control-error label="Email" controlName="email" [formGroup]="formGroup!">
                    </vh-control-error>
                </mat-error>
            </mat-form-field>
        </div>

        <mat-form-field appearance="outline" hideRequiredMarker class="mt-3 w-full">
            <mat-label>Subject</mat-label>
            <input type="text" matInput formControlName="subject">
            <mat-error *ngIf="formGroup?.get('subject')?.invalid">
                <vh-control-error label="Subject" controlName="subject" [formGroup]="formGroup!">
                </vh-control-error>
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" hideRequiredMarker class="mt-3 w-full">
            <mat-label>Comment</mat-label>
            <textarea rows="2" matInput formControlName="comment" cdkTextareaAutosize></textarea>
            <mat-error *ngIf="formGroup?.get('comment')?.invalid">
                <vh-control-error label="Comment" controlName="comment" [formGroup]="formGroup!">
                </vh-control-error>
            </mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>

<div mat-dialog-actions class="flex !justify-end !items-center !space-x-3 !px-6 !pb-6 !pt-0">
    <button mat-stroked-button mat-dialog-close type="button" aria-label="Cancel">
        <span class="text-vhGray-100 font-semibold tracking-normal">Cancel</span>
    </button>

    <button cdkFocusInitial mat-flat-button type="submit" form="contactUsForm" [disabled]="formGroup?.invalid"
        color="primary" aria-label="Submit form" class="font-semibold tracking-normal">
        <span class="font-semibold tracking-normal">Submit</span>
    </button>

</div>