<div class="flex items-center -my-2 -mr-2">
    <span class="grow font-medium overflow-hidden">{{ data.message }}</span>
    <button aria-label="Close" mat-icon-button *ngIf="!data.actionText; else actionText" (click)="snackBarRef.dismiss()">
        <mat-icon svgIcon="vhc-close"></mat-icon>
    </button>
    <ng-template #actionText>
        <button aria-label="Close" mat-flat-button color="primary" (click)="snackBarRef.dismiss()" *ngIf="!data.action">
            {{ data.actionText }}
        </button>
        <button aria-label="Go to action" mat-flat-button color="primary" (click)="data.action()" *ngIf="data.action">
            {{ data.actionText }}
        </button>
    </ng-template>
</div>