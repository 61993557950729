import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

export interface ISnackBarAction {
  actionText?: string;
  action?: () => void;
}

export interface ISnackBarData extends ISnackBarAction {
  message: string;
}

@Component({
  selector: 'vh-snack-bar',
  standalone: true,
  imports: [
    NgIf,
    MatIcon,
    MatButton,
    MatIconButton
  ],
  templateUrl: './snack-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackBarComponent {
  constructor(
    public snackBarRef: MatSnackBarRef<SnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: ISnackBarData
  ) { }
}
