import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ErrorStateMatcher, MatOption } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';

import { MatInput } from '@angular/material/input';

import { parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';
import { BehaviorSubject, Subject, take, takeUntil } from 'rxjs';


import { MatSelect, MatSelectTrigger } from '@angular/material/select';

import { AsyncPipe, NgFor, NgIf, NgStyle } from '@angular/common';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { FlagLocationPipe } from '../../../../pipes/flag-location.pipe';
import { Country, defaultCountry, supportedPhoneCountries } from '../../../../constants/countries';
import { filterCountries } from '../../../../utils/utils';
import { MatIcon } from '@angular/material/icon';

export interface IPhoneNumber {
  regionCode: string;
  number?: string | null;
}

type onChangeFunctionType = (value: IPhoneNumber) => void;
type onTouchFunctionType = () => void;

@Component({
  selector: 'vh-phone-input',
  standalone: true,
  imports: [
    NgStyle,
    NgIf,
    NgFor,
    AsyncPipe,
    ReactiveFormsModule,
    MatOption,
    MatSelectTrigger,
    MatSelect,
    FlagLocationPipe,
    NgxMatSelectSearchModule,
    MatInput,
    MatIcon
  ],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: PhoneInputComponent
    }
  ],
  templateUrl: './phone-input.component.html'
})
export class PhoneInputComponent implements OnInit, OnDestroy, MatFormFieldControl<IPhoneNumber>, ControlValueAccessor {

  public static nextId = 0;

  private _placeholder: string = '';
  private _value: string = '';
  private _unsubscribe = new Subject<void>();

  @ViewChild('focusInput', { read: ElementRef, static: true })
  public input: ElementRef | null = null;

  public get value() {
    return this.form?.get('phone')?.value;
  }

  @Input()
  public set value(value: IPhoneNumber) {
    if (value) {
      let pn: PhoneNumber | null = null;

      try {
        pn = parsePhoneNumber(value.number ?? '');
      } catch {
        // Intentionally left blank
      }

      if (pn?.isValid()) {
        this.form?.get('phone')?.setValue(pn.nationalNumber);
        this.form?.get('country')?.setValue(supportedPhoneCountries.find(item => item.code === pn.country) ?? defaultCountry);
      } else {
        this.form?.get('country')?.setValue(supportedPhoneCountries.find(item => item.code === value.regionCode) ?? defaultCountry);
        this.form?.get('phone')?.setValue(value.number);
      }
    } else {
      this.form?.get('country')?.setValue(defaultCountry);
      this.form?.get('phone')?.setValue(null);
    }

    this.stateChanges.next();
  }



  @HostBinding()
  public id = `phone-input-id-${PhoneInputComponent.nextId++}`;

  @HostBinding('class')
  public cssClasses = 'flex items-center';

  @HostBinding('attr.aria-describedBy')
  public describedBy = '';

  @Input()
  public set placeholder(pl: string) {
    this._placeholder = pl;
    this.stateChanges.next();
  }
  public get placeholder() {
    return this._placeholder;
  }

  @Input()
  public required: boolean = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);

    if (this._disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    this.stateChanges.next();
  }
  private _disabled = false;

  public get empty(): boolean {
    const haveValue = !!this._value;
    return !haveValue;
  }

  public get errorState(): boolean {
    if (this.ngControl != null) {
      return this.errorMatcher.isErrorState(this.ngControl.control, null);
    }
    return false;
  }

  public controlType = 'phone-input';
  public form: UntypedFormGroup;
  public stateChanges = new Subject<void>();
  public selectedCountry = defaultCountry;
  public filteredCountries = new BehaviorSubject<Country[]>([...supportedPhoneCountries]);
  public shouldLabelFloat = true;
  public focused = false;

  public onChange: onChangeFunctionType = () => { };
  public onTouch: onTouchFunctionType = () => { };

  constructor(
    private focusMonitor: FocusMonitor,
    private formBuilder: UntypedFormBuilder,
    private errorMatcher: ErrorStateMatcher,
    @Optional() @Self() public ngControl: NgControl) {
    this.form = this.formBuilder.group({
      country: [defaultCountry, []],
      countryFilter: [null, []],
      phone: [null, []]
    });

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public ngOnInit(): void {
    this.focusMonitor.monitor(this.input!).pipe(takeUntil(this._unsubscribe))
      .subscribe(focused => {
        this.focused = !!focused;
        this.stateChanges.next();
      });

    this.focusMonitor.monitor(this.input!).pipe(take(1)).subscribe(() => this.onTouch());

    this.form.get('countryFilter')?.valueChanges.pipe(takeUntil(this._unsubscribe))
      .subscribe(value => this.filterCountries(value));

    this.form.valueChanges.pipe(takeUntil(this._unsubscribe)).subscribe(() => {
      const code = this.form?.get('country')?.value?.code;
      const number = this.form?.get('phone')?.value;

      if (!number) {
        this.onChange({ regionCode: code, number: null });
      } else {
        let pn: PhoneNumber | null = null;

        try {
          pn = parsePhoneNumber(number, code);
        } catch {
          // Intentionally left blank
        }

        this.onChange({
          regionCode: code || null,
          number: pn?.isValid() ? pn.format('E.164') : number
        });

        if (pn?.isValid() && pn.nationalNumber !== number) {
          this.form.get('phone')?.setValue(pn.nationalNumber, { emitEvent: false });
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();

    this.focusMonitor.stopMonitoring(this.input!);
    this.stateChanges.complete();
  }

  public writeValue(value: IPhoneNumber): void {
    this.value = value;
  }
  public registerOnChange(fn: onChangeFunctionType): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: onTouchFunctionType): void {
    this.onTouch = fn;
  }

  public setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  public onContainerClick(): void {
    this.focusMonitor.focusVia(this.input!, 'program');
  }

  public filterCountries(filter: string | null): void {
    this.filteredCountries.next(filterCountries(supportedPhoneCountries, filter));
  }

  public onCopy(event: ClipboardEvent) {
    event.preventDefault();

    const code = this.form?.get('country')?.value?.code;
    const number = this.form?.get('phone')?.value;
    const clipboardData = event.clipboardData;

    if (!number) {
      clipboardData?.setData('text', '');
      return;
    }

    let pn: PhoneNumber | null = null;

    try {
      pn = parsePhoneNumber(number, code);
    } catch {
      // Intentionally left blank
    }

    clipboardData?.setData('text', pn?.isValid() ? pn.format('E.164') : number);
  }

  public onPaste(event: ClipboardEvent) {
    const data = event.clipboardData?.getData('text');

    if (!data) {
      return;
    }

    const code = this.form?.get('country')?.value?.code;

    let pn: PhoneNumber | null = null;

    try {
      pn = parsePhoneNumber(data);
    } catch {
      // Intentionally left blank
    }

    if (pn?.isValid()) {
      this.value = { number: pn.format('E.164'), regionCode: pn.country! };
    } else {
      this.value = { number: data, regionCode: code };
    }

    event.stopPropagation();
    event.preventDefault();
  }

}
