import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { SUPPORT_EMAIL, SUPPORT_PHONE, VHC_LINK } from '../../../constants/misc';

@Component({
  selector: 'vh-about-us-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButton
  ],
  templateUrl: './about-us-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboutUsDialogComponent {
  public supportPhone = SUPPORT_PHONE;
  public supportEmail = SUPPORT_EMAIL;
  public vhcLink = VHC_LINK;
}
