export const SUPPORT_PHONE: string = '1-855-634-2600';
export const SUPPORT_EMAIL: string = 'reservations@vacayhome.com';
export const VHC_LINK: string = 'https://vacayhomeconnect.com/';
export const URL_DATE_FORMAT: string = 'DD-MM-YYYY';
export const MAX_REVIEW_SCORE = 5;

/** These values correspond to tailwind value. If you change there (tailwind config) it would be nice to change here as well.*/
export const SCREEN_XS_MIN = 0;
export const SCREEN_XS_MAX = 639;

export const SCREEN_SM_MIN = 640;
export const SCREEN_SM_MAX = 767;

export const SCREEN_MD_MIN = 768;
export const SCREEN_MD_MAX = 1023;

export const SCREEN_LG_MIN = 1024;
export const SCREEN_LG_MAX = 1279;

export const SCREEN_XL_MIN = 1280;
export const SCREEN_XL_MAX = 1535;

export const PHOTOS_PREVIEW_WIDTH = '99%';
export const PHOTOS_PREVIEW_HEIGHT = '7000';