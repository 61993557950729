import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

export interface IErrorDetails {
  min?: number;
  max?: number;
  customMessage?: string;
}

@Component({
  selector: 'vh-control-error',
  standalone: true,
  imports: [AsyncPipe],
  templateUrl: './control-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlErrorComponent implements OnInit, OnDestroy {
  @Input()
  public controlName!: string;

  @Input()
  public label!: string;

  @Input()
  public errorDetails?: IErrorDetails;

  @Input()
  public formGroup!: FormGroup | UntypedFormGroup;

  public errorMessage = new BehaviorSubject<string | null>(null);

  private _unsubscribe = new Subject<void>();

  public ngOnInit(): void {
    const control = this.formGroup.get(this.controlName);

    this.makeError();

    control?.statusChanges
      .pipe(takeUntil(this._unsubscribe))
      .subscribe(() => {
        this.makeError();
      });
  }

  public ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  private makeError(): void {
    const control = this.formGroup.get(this.controlName);
    const errors = Object.keys(control?.errors ?? {});

    if (!control?.invalid || !errors.length) {
      if (this.errorMessage.value !== null) {
        this.errorMessage.next(null);
      }
    } else {
      const customMessage = this.errorDetails?.customMessage;
      let message = '';

      switch (errors[0]) {
        case 'required': message = customMessage ?? `${this.label} is required`; break;
        case 'cardNumber':
        case 'phone':
        case 'cardSecurityCode':
        case 'email': message = customMessage ?? `${this.label} is invalid`; break;
        case 'whitespace': message = customMessage ?? `${this.label} cannot be whitespace`; break;
        case 'pattern': message = customMessage ?? `${this.label} has an incorrect format`; break;
        case 'min': message = customMessage ?? `${this.label} cannot be less than ${this.errorDetails?.min}`; break;
        case 'max': message = customMessage ?? `${this.label} cannot be more than ${this.errorDetails?.max}`; break;
        case 'minlength': message = customMessage ?? `${this.label} cannot be less than ${this.errorDetails?.min} chars`; break;
        case 'maxlength': message = customMessage ?? `${this.label} cannot be more than ${this.errorDetails?.max} chars`; break;
        case 'cardFutureDate': message = customMessage ?? `${this.label} is in the past`; break;
        case 'supportedCardType': message = customMessage ?? `Unsupported card type`; break;
      }

      if (this.errorMessage.value !== message) {
        this.errorMessage.next(message);
      }
    }
  }
}
