import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface ILoaderStatus {
  show: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private _showLoader$ = new Subject<ILoaderStatus>();
  private _loaderCount = 0;
  private _disabled = false;

  public get state$(): Observable<ILoaderStatus> { return this._showLoader$.asObservable(); }

  public show(): void {
    if (!this._disabled) {
      this._showLoader$.next({ show: true });
      this._loaderCount++;
    }
  }

  public hide(forceHide?: boolean): void {
    if (this._loaderCount > 0) {
      this._loaderCount--;
    }

    if (!this._loaderCount || forceHide) {
      this._showLoader$.next({ show: false });
    }
  }

  /**
   * Disables the loader. From this call until enable is called the show method will have no effect.
   */
  public disable() {
    this._disabled = true;
  }

  /**
   * Enables the loader. Will have an effect only after the disable command was called.
   * After this call, the show method will work once again.
   */
  public enable() {
    this._disabled = false;
  }
}
