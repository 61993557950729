import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CustomValidators } from '../../../utils/custom-validators';
import { MatButton } from '@angular/material/button';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatError, MatFormField, MatHint, MatLabel } from '@angular/material/form-field';
import { NgIf } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { IPhoneNumber, PhoneInputComponent } from '../../form-helpers/controls/phone-input/phone-input.component';
import { ControlErrorComponent } from '../../form-helpers/control-error/control-error.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoaderService } from '../../../services/loader.service';
import { hosts } from '../../../constants/hosts';
import { catchError, finalize } from 'rxjs';
import { extractErrors } from '../../../utils/utils';
import { SnackBarService } from '../../../services/snack-bar.service';
import { PhoneHintPipe } from '../../../pipes/phone-hint.pipe';
import { secrets } from '../../../../../environments/secrets';

@Component({
  selector: 'vh-contact-us-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButton,
    MatLabel,
    MatError,
    MatFormField,
    MatHint,
    NgIf,
    MatInput,
    PhoneInputComponent,
    ControlErrorComponent,
    PhoneHintPipe,
    ReactiveFormsModule
  ],
  templateUrl: './contact-us-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactUsDialogComponent implements OnInit {
  public formGroup: FormGroup | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private loader: LoaderService,
    private snackBar: SnackBarService,
    private dialogRef: MatDialogRef<ContactUsDialogComponent>
  ) { }

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      firstName: [null, [Validators.required, CustomValidators.whitespaceGuard]],
      lastName: [null, [Validators.required, CustomValidators.whitespaceGuard]],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, [CustomValidators.phoneRequired, CustomValidators.phone]],
      subject: [null, [Validators.required, CustomValidators.whitespaceGuard]],
      comment: [null, [Validators.required, CustomValidators.whitespaceGuard]]
    });
  }

  public submit() {
    if (!this.formGroup?.valid) {
      return;
    }

    this.loader.show();

    const headers = new HttpHeaders({ 'x-api-key': secrets.RES_KEY });

    this.http.post(`${hosts.sentinel}/contact_us`, {
      first_name: this.formGroup.get('firstName')?.value?.trim(),
      last_name: this.formGroup.get('lastName')?.value?.trim(),
      phone: (this.formGroup.get('phone')?.value as IPhoneNumber).number,
      email: this.formGroup.get('email')?.value?.trim(),
      subject: this.formGroup.get('subject')?.value?.trim(),
      comment: this.formGroup.get('comment')?.value?.trim(),
    }, { headers }).pipe(
      catchError(err => {
        this.snackBar.showError(`Error: ${extractErrors(err)}`);
        throw err;
      }),
      finalize(() => this.loader.hide())
    ).subscribe((result: any) => {
      if (result.errors?.length) {
        this.snackBar.showError(`Error: ${result.errors?.join(', ')}`);
        return;
      }

      this.snackBar.showInfo('Message sent successfully. You will receive a response through the email address you entered.');
      this.dialogRef.close();
    });
  }
}
