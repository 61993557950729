import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'vh-middle-layout',
  standalone: true,
  imports: [],
  templateUrl: './middle-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MiddleLayoutComponent {
  @HostBinding('class')
  public cssClasses = 'block lg:grid lg:grid-cols-12';
}
