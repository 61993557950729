// If you add a card type here, please also add an icon for it in assets/images/card-icons.svg and also add it to the card-type-icon pipe.
export enum CardType {
    AmericanExpress = 'American Express',
    Dankort = 'Dankort',
    DinersClub = 'Diners Club',
    Discover = 'Discover',
    Elo = 'Elo',
    Forbru = 'Forbrugsforeningen',
    Jcb = 'JCB',
    Maestro = 'Maestro',
    Mastercard = 'Mastercard',
    Troy = 'Troy',
    Uatp = 'UATP',
    UnionPay = 'UnionPay',
    Visa = 'Visa'
}