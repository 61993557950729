import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { ISnackBarAction, ISnackBarData, SnackBarComponent } from '../components/snack-bar/snack-bar.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  private readonly LOW_PRIORITY_DURATION = 3000;
  private readonly HIGH_PRIORITY_DURATION = 10000;

  private _queue: MatSnackBarConfig<ISnackBarData>[] = [];
  private _isInstanceVisible = false;

  constructor(
    private snackBar: MatSnackBar
  ) { }

  /**
   * Will show an error message for a longer period of time.
   * @param message The message to show.
   * @param action Action to display on the snackBar (button text and callable function).
   */
  public showError(message: string, action?: ISnackBarAction) {
    this.enqueueMessage(message, this.HIGH_PRIORITY_DURATION, action);
  }

  /**
   * Will show an info message for a shorter period of time.
   * @param message The message to show.
   * @param action Action to display on the snackBar (button text and callable function).
   */
  public showInfo(message: string, action?: ISnackBarAction) {
    this.enqueueMessage(message, this.LOW_PRIORITY_DURATION, action);
  }

  private enqueueMessage(message: string, duration: number, action?: ISnackBarAction) {
    const config = new MatSnackBarConfig<ISnackBarData>();

    config.duration = duration;
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    config.panelClass = 'vh-snack-bar';

    const data: ISnackBarData = {
      message,
      ...action
    };

    config.data = data;

    this._queue.push(config);

    if (!this._isInstanceVisible) {
      this.showNext();
    }
  }

  private showNext() {
    if (this._queue.length !== 0) {
      const config = this._queue.shift();
      this._isInstanceVisible = true;

      this.snackBar.openFromComponent(SnackBarComponent, config)
        .afterDismissed().pipe(take(1)).subscribe(() => {
          this._isInstanceVisible = false;
          this.showNext();
        });
    }
  }
}
